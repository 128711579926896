import React from 'react'
import { Props } from './props.type'

export const Folders: React.FC<Props> = (props) => {
  const { width, height, theme } = props
  const svgWidth = width || '152px'
  const svgHeight = height || '169px'
  return (
    <svg width={svgWidth} height={svgHeight} viewBox="0 0 152 169" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(2.000000, 2.000000)">
          <path d="M74,124.060611 L74,158.723567 C74,162.037275 71.3137085,164.723567 68,164.723567 C66.9452118,164.723567 65.9090627,164.445504 64.995996,163.917406 L6.67495026,130.185774 L6.67495026,130.185774 C2.54398439,127.796512 1.42108547e-14,123.386278 1.42108547e-14,118.614125 L1.42108547e-14,48.436129 C1.42108547e-14,43.6639756 2.54398439,39.2537412 6.67495026,36.8644799 L67.3072083,1.79609278 C71.4477337,-0.598697595 76.5522663,-0.598697595 80.6927917,1.79609278 L141.32505,36.8644799 C145.456016,39.2537412 148,43.6639756 148,48.436129 L148,118.614125 C148,123.386278 145.456016,127.796512 141.32505,130.185774 L92.2812226,158.551663" id="Path-Copy-6" stroke={theme.colors.primary60} strokeWidth="3" strokeLinecap="round" />
          <path d="M80,75.4190948 C80,70.9185608 80,64.1677599 80,55.166692 L89.166692,46 L122.184615,46 C122.924232,46 123.52381,46.5995776 123.52381,47.3391941 C123.52381,68.5174474 123.52381,82.6975707 123.52381,89.8795641 L123.52381,98.4049324 C123.52381,98.9609384 123.52381,99.7949476 123.52381,100.90696 C123.52381,101.646576 122.924232,102.246154 122.184615,102.246154 C119.775905,102.246154 117.969372,102.246154 116.765016,102.246154 L107.980724,68.9485742 L80,75.4190948 Z" id="Path" fill={theme.colors.primary20} transform="translate(101.761905, 74.123077) rotate(-345.000000) translate(-101.761905, -74.123077) " />
          <path d="M80,75.4190948 C80,70.9185608 80,64.1677599 80,55.166692 L89.166692,46 L122.184615,46 C122.924232,46 123.52381,46.5995776 123.52381,47.3391941 C123.52381,68.5174474 123.52381,82.6975707 123.52381,89.8795641 L123.52381,98.4049324 L123.52381,98.4049324 C123.52381,98.9609384 123.52381,99.7949476 123.52381,100.90696 C123.52381,101.646576 122.924232,102.246154 122.184615,102.246154 C119.775905,102.246154 117.969372,102.246154 116.765016,102.246154" id="Path-Copy-7" stroke={theme.colors.primary60} strokeWidth="3" transform="translate(101.761905, 74.123077) rotate(-345.000000) translate(-101.761905, -74.123077) " />
          <path d="M96.001211,42.535856 L96.001211,50.4262472 C96.001211,51.1658637 95.4016334,51.7654413 94.6620169,51.7654413 L86.7369994,51.7654413" id="Path-Copy-10" stroke={theme.colors.primary60} strokeWidth="3" transform="translate(91.369105, 47.150649) rotate(-345.000000) translate(-91.369105, -47.150649) " />
          <path d="M48.907368,38.345876 L48.907368,46.2362671 C48.907368,46.9758836 48.3077904,47.5754613 47.5681739,47.5754613 L39.6431564,47.5754613" id="Path-Copy-10" stroke={theme.colors.primary60} strokeWidth="3" fill={theme.colors.primary20} transform="translate(44.275262, 42.960669) rotate(-15.000000) translate(-44.275262, -42.960669) " />
          <path d="M40.6569874,54.4287231 C40.6569874,51.8308869 40.6569874,47.9341327 40.6569874,42.7384605 L49.8236794,33.5717685 L82.8416028,33.5717685 C83.5812193,33.5717685 84.1807969,34.1713461 84.1807969,34.9109626 C84.1807969,43.0635825 84.1807969,50.179161 84.1807969,56.257698" id="Path" stroke={theme.colors.primary60} strokeWidth="3" transform="translate(62.418892, 44.914733) rotate(-15.000000) translate(-62.418892, -44.914733) " />
          <path d="M53.634268,124 L34,124 C32.8954305,124 32,123.104569 32,122 L32,62 C32,60.8954305 32.8954305,60 34,60 L53.5584816,60 C54.4193424,60 55.1836201,60.5508602 55.4558482,61.3675445 L58.3333333,70 L107,70 C108.104569,70 109,70.8954305 109,72 L109,122 C109,123.104569 108.104569,124 107,124 L74,124" id="Path" stroke={theme.colors.primary60} strokeWidth="3" strokeLinecap="round" />
          <line x1="32" y1="80.4611735" x2="96.5731" y2="80.4611735" id="Line-7" stroke={theme.colors.primary60} strokeWidth="3" strokeLinecap="round" />
        </g>
      </g>
    </svg>
  )
}

export default Folders
