import React from 'react'
import { Props } from './props.type'

export const Launch: React.FC<Props> = (props) => {
  const { width, height, theme } = props
  const svgWidth = width || '165px'
  const svgHeight = height || '184px'
  return (
    <svg width={svgWidth} height={svgHeight} viewBox="0 0 165 184" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(2.000000, 1.000000)" fillRule="nonzero">
          <path d="M80.5,163.67556 L80.5,177.071797 C80.5,179.280936 78.709139,181.071797 76.5,181.071797 C75.7978533,181.071797 75.1080768,180.886972 74.5,180.535898 L5.39265015,140.636751 L5.39265015,140.636751 C2.29863938,138.850423 0.39265015,135.549153 0.39265015,131.976497 L0.39265015,51.0235027 C0.39265015,47.4508468 2.29863938,44.1495766 5.39265015,42.3632487 L75.5,1.88675135 C78.5940108,0.100423396 82.4059892,0.100423396 85.5,1.88675135 L155.60735,42.3632487 C158.701361,44.1495766 160.60735,47.4508468 160.60735,51.0235027 L160.60735,131.976497 C160.60735,135.549153 158.701361,138.850423 155.60735,140.636751 L94.0407624,176.182237" id="Path" stroke={theme.colors.primary60} strokeWidth="3" strokeLinecap="round" />
          <g id="Group-11" transform="translate(47.000000, 21.000000)" stroke={theme.colors.primary60} strokeWidth="2">
            <path d="M53.9942579,39.5219471 L55.8288797,35.8527035 C56.3228582,34.8647465 57.5242043,34.4642978 58.5121613,34.9582763 C58.8992161,35.1518037 59.2130611,35.4656487 59.4065885,35.8527035 L61.2412103,39.5219471 C62.5603369,42.1602003 63.2470936,45.0693486 63.2470936,48.0190054 L63.2470936,122.095329 C63.2470936,122.647613 62.7993784,123.095329 62.2470936,123.095329 L52.9883746,123.095329 C52.4360898,123.095329 51.9883746,122.647613 51.9883746,122.095329 L51.9883746,48.0190054 C51.9883746,45.0693486 52.6751313,42.1602003 53.9942579,39.5219471 Z" id="Rectangle" fill={theme.colors.white} strokeLinejoin="round" />
            <path d="M5.75878973,39.5219471 L7.59341152,35.8527035 C8.08739002,34.8647465 9.2887361,34.4642978 10.2766931,34.9582763 C10.6637479,35.1518037 10.9775929,35.4656487 11.1711203,35.8527035 L13.0057421,39.5219471 C14.3248687,42.1602003 15.0116254,45.0693486 15.0116254,48.0190054 L15.0116254,122.095329 C15.0116254,122.647613 14.5639102,123.095329 14.0116254,123.095329 L4.75290636,123.095329 C4.20062161,123.095329 3.75290636,122.647613 3.75290636,122.095329 L3.75290636,48.0190054 C3.75290636,45.0693486 4.43966311,42.1602003 5.75878973,39.5219471 Z" id="Rectangle" fill={theme.colors.white} strokeLinejoin="round" />
            <path d="M26.5571232,7.82445311 L30.2061982,2.5285507 C31.4596375,0.709434189 33.9504362,0.250862247 35.7695527,1.5043016 C36.1704572,1.78053976 36.5175637,2.12764628 36.7938018,2.5285507 L40.4428768,7.82445311 C44.9645408,14.386744 47.3857535,22.1679451 47.3857535,30.1372052 L47.3857535,93.8226591 L47.3857535,93.8226591 L19.6142465,93.8226591 L19.6142465,30.1372052 C19.6142465,22.1679451 22.0354592,14.386744 26.5571232,7.82445311 Z" id="Rectangle" fill={theme.colors.primary60} strokeLinejoin="round" />
            <path d="M40.5745738,117.355362 L41.6399673,64.4923457 L45.0007027,77.8303078 C46.8757338,85.2718617 50.6334929,92.105976 55.9127551,97.6757028 L59.211122,101.155546 C62.0658223,104.167311 63.9962059,107.93508 64.7730365,112.011424 L65.5485066,116.080629 C65.5463599,116.477108 65.4504565,116.810566 65.2566953,117.04474 C65.0889845,117.247429 64.8478844,117.355362 64.582866,117.355362 L40.5745738,117.355362 Z" id="Path-3" fill={theme.colors.primary60} />
            <path d="M1.02035692,117.355362 L2.08575042,64.4923457 L5.44648581,77.8303078 C7.32151692,85.2718617 11.079276,92.105976 16.3585383,97.6757028 L19.6569052,101.155546 C22.5116054,104.167311 24.441989,107.93508 25.2188196,112.011424 L25.9942897,116.080629 C25.992143,116.477108 25.8962396,116.810566 25.7024785,117.04474 C25.5347676,117.247429 25.2936675,117.355362 25.0286491,117.355362 L1.02035692,117.355362 Z" id="Path-3-Copy" fill={theme.colors.primary60} transform="translate(13.722892, 87.691223) scale(-1, 1) translate(-13.722892, -87.691223) " />
            <path d="M26.9474568,46.6158047 L29.0688728,42.5574438 C30.348114,40.1101998 33.3690271,39.1633464 35.816271,40.4425876 C36.7204907,40.9152479 37.4584669,41.6532241 37.9311272,42.5574438 L40.0525432,46.6158047 C41.8462831,50.0473072 42.7831325,53.8618634 42.7831325,57.7339058 L42.7831325,122.875685 L42.7831325,122.875685 L24.2168675,122.875685 L24.2168675,57.7339058 C24.2168675,53.8618634 25.1537169,50.0473072 26.9474568,46.6158047 Z" id="Rectangle" fill={theme.colors.white} strokeLinejoin="round" />
            <path d="M46.626506,125.104601 L20.373494,125.104601 L20.373494,118.032312 C20.373494,116.079353 21.1650869,114.311281 22.4449195,113.031448 C23.7247521,111.751616 25.4928244,110.960023 27.4457831,110.960023 L39.5542169,110.960023 C41.5071756,110.960023 43.2752479,111.751616 44.5550805,113.031448 C45.8349131,114.311281 46.626506,116.079353 46.626506,118.032312 L46.626506,125.104601 Z" id="Rectangle" fill={theme.colors.white} />
            <line x1="33.5" y1="101.484119" x2="33.5" y2="129.203332" id="Line" fill={theme.colors.primary20} strokeLinecap="round" />
          </g>
        </g>
      </g>
    </svg>
  )
}
export default Launch
